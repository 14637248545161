import { apiClient } from './apiConfig';
import { PATH } from './apiConst';

export const blogCreate = (id, data) => {
  return apiClient({
    url: PATH?.blog?.blogCreate(id),
    method: 'POST',
    data,
  });
};

export const blogGet = (id) => {
  return apiClient({
    url: PATH?.blog?.blogGet(id),
    method: 'GET',
  });
};

export const blogDelete = (id) => {
  return apiClient({
    url: PATH?.blog?.blogDelete(id),
    method: 'PUT',
  });
};

export const blogUpdate = (id, data) => {
  return apiClient({
    url: PATH?.blog?.blogUpdate(id),
    method: 'PUT',
    data
  });
};

export const blogDeactivate = (id) => {
  return apiClient({
    url: PATH?.blog?.blogDeactivate(id),
    method: 'PUT',
  });
};

export const blogActive = (id) => {
  return apiClient({
    url: PATH?.blog?.blogActive(id),
    method: 'PUT',
  });
};

export const blogAdmin = () => {
  return apiClient({
    url: PATH?.blog?.blogAdmin(),
    method: 'GET',
  });
};

export const blogUserList = () => {
  return apiClient({
    url: PATH?.blog?.blogUserList(),
    method: 'GET',
  });
};
export const updateUserToAdmin = (data) => {
  return apiClient({
    url: PATH?.blog?.userUpdateAdmin(),
    method: 'POST',
    data
  });
};
export const blogUserDelete = (id) => {
  return apiClient({
    url: PATH?.blog?.blogUserDelete(),
    method: 'PUT',
    data: { _id: id },
  });
};
export const userStatus = (id, type) => {
  return apiClient({
    url: PATH?.blog?.userActiveInActiveStatus(id, type),
    method: "PUT",
  });
};

