import React from 'react';
import HTMLReactParser from 'html-react-parser';

const PreviewPage = ({ previewData, showPreview, imageChange }) => {
  const loginData = localStorage.getItem('user');
  const allData = JSON.parse(loginData);

  return (
    <div className="m-auto max-w-[1200px]">
      <div className="">
        <div className="blog_heading">{previewData?.title}</div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2 mt-4">
            <div className="h-10 w-10 bg-white rounded-full flex justify-center items-center text-xl font-bold text-blue-400 shadow-2xl">
              {allData?.firstName.charAt(0).toUpperCase()}
            </div>
            <div className="flex flex-col">
              <p className="m-0 p-0 font-bold">
                {allData?.firstName} {allData?.lastName}
              </p>
              <span className="text-[#7780a1]">Jan 13, 2022</span>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="h-10 w-10 bg-white rounded-full flex justify-center items-center text-xl font-bold text-blue-400 shadow-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                width="30"
                height="30"
                className="fill-[#7780a1]"
              >
                <g>
                  <path d="M24,12.073c0,5.989-4.394,10.954-10.13,11.855v-8.363h2.789l0.531-3.46H13.87V9.86c0-0.947,0.464-1.869,1.95-1.869h1.509 V5.045c0,0-1.37-0.234-2.679-0.234c-2.734,0-4.52,1.657-4.52,4.656v2.637H7.091v3.46h3.039v8.363C4.395,23.025,0,18.061,0,12.073 c0-6.627,5.373-12,12-12S24,5.445,24,12.073z" />
                </g>
              </svg>
            </div>
            <div className="h-10 w-10 bg-white rounded-full flex justify-center items-center text-xl font-bold text-blue-400 shadow-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                width="30"
                height="30"
                className="fill-[#7780a1]"
              >
                <g>
                  <path d="M12,2.162c3.204,0,3.584,0.012,4.849,0.07c1.308,0.06,2.655,0.358,3.608,1.311c0.962,0.962,1.251,2.296,1.311,3.608   c0.058,1.265,0.07,1.645,0.07,4.849c0,3.204-0.012,3.584-0.07,4.849c-0.059,1.301-0.364,2.661-1.311,3.608   c-0.962,0.962-2.295,1.251-3.608,1.311c-1.265,0.058-1.645,0.07-4.849,0.07s-3.584-0.012-4.849-0.07   c-1.291-0.059-2.669-0.371-3.608-1.311c-0.957-0.957-1.251-2.304-1.311-3.608c-0.058-1.265-0.07-1.645-0.07-4.849   c0-3.204,0.012-3.584,0.07-4.849c0.059-1.296,0.367-2.664,1.311-3.608c0.96-0.96,2.299-1.251,3.608-1.311   C8.416,2.174,8.796,2.162,12,2.162 M12,0C8.741,0,8.332,0.014,7.052,0.072C5.197,0.157,3.355,0.673,2.014,2.014   C0.668,3.36,0.157,5.198,0.072,7.052C0.014,8.332,0,8.741,0,12c0,3.259,0.014,3.668,0.072,4.948c0.085,1.853,0.603,3.7,1.942,5.038   c1.345,1.345,3.186,1.857,5.038,1.942C8.332,23.986,8.741,24,12,24c3.259,0,3.668-0.014,4.948-0.072   c1.854-0.085,3.698-0.602,5.038-1.942c1.347-1.347,1.857-3.184,1.942-5.038C23.986,15.668,24,15.259,24,12   c0-3.259-0.014-3.668-0.072-4.948c-0.085-1.855-0.602-3.698-1.942-5.038c-1.343-1.343-3.189-1.858-5.038-1.942   C15.668,0.014,15.259,0,12,0z" />
                  <path d="M12,5.838c-3.403,0-6.162,2.759-6.162,6.162c0,3.403,2.759,6.162,6.162,6.162s6.162-2.759,6.162-6.162   C18.162,8.597,15.403,5.838,12,5.838z M12,16c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S14.209,16,12,16z" />
                  <circle cx="18.406" cy="5.594" r="1.44" />
                </g>
              </svg>
            </div>
            <div className="h-10 w-10 bg-white rounded-full flex justify-center items-center text-xl font-bold text-blue-400 shadow-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512.629 512.629"
                width="25"
                height="25"
                className="fill-[#7780a1]"
              >
                <g>
                  <path d="M0.006,256.014c-0.218,54.774,44.009,99.354,98.784,99.572c25.226,0.1,49.543-9.416,68-26.612l147.797,66.731   c-1.027,5.526-1.59,11.127-1.685,16.747c-0.174,55.152,44.393,100.002,99.545,100.177s100.002-44.393,100.177-99.545   s-44.393-100.002-99.545-100.177c-32.951-0.104-63.832,16.053-82.534,43.182L191.686,293.39   c9.863-23.816,9.901-50.567,0.107-74.411l138.667-63.019c31.225,45.161,93.148,56.457,138.309,25.232S525.225,88.045,494,42.884   s-93.148-56.457-138.309-25.232c-26.895,18.596-42.926,49.227-42.875,81.925c0.09,5.628,0.661,11.237,1.707,16.768L167.11,183.331   c-39.939-37.503-102.718-35.528-140.221,4.411C9.532,206.228-0.088,230.657,0.006,256.014z" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="" style={{ borderRadius: '20px', overflow: 'hidden' }}>
          <img
            src={
              showPreview && !imageChange
                ? previewData?.image
                : URL.createObjectURL(previewData?.image)
            }
            className="mb-5 mt-5"
            alt=""
            width={0}
            height={0}
            style={{
              borderRadius: '20px',
              width: '100%',
              height: '500px',
              objectFit: 'cover',
            }}
          />
        </div>
        <td className="whitespace-nowrap">
          <div className="text-sm text-gray-500 flex gap-1 flex-wrap">
            {previewData?.tags.map((item, i) => (
              <div
                className="px-2 py-1 rounded-full bg-blue-400 text-white"
                key={i}
              >
                {item}
              </div>
            ))}
          </div>
        </td>
        <div className="mt-4">
          <div className="mt-3">
            <p className="web_text">
              {HTMLReactParser(previewData?.description)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPage;
