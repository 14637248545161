import React, { useEffect, useState } from 'react';
import Pagination from './Pagination';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  blogActive,
  blogDeactivate,
  blogDelete,
  blogGet,
} from '../service/api';
import { useDispatch } from 'react-redux';
import { setOption } from '../redux/ManageSlice';
import notFound from '../assets/images/notFound.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmationModal } from '../comman/ConfirmationModal ';
import ToolTip from '../comman/toolTip';
import Loader from '../comman/loader';


const ManageProduct = ({ setEditData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const loginData = localStorage.getItem('user');
  const allData = JSON.parse(loginData);
  const userId = allData.id;
  const productsPerPage = 5;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = blogData.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(blogData?.length / productsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (product) => {
    setEditData(product);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);
    blogDelete(deleteId)
      .then(() => {
        fetchBlogData();
        toast.success('Blog post deleted successfully');
        setShowModal(false);
      })
      .catch((error) => {
        console.error('Failed to delete blog post:', error);
        toast.error('Something went wrong');
        setShowModal(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBlogData();
  }, [currentPage]);

  const fetchBlogData = () => {
    setLoading(true);
    blogGet(userId)
      .then((response) => {
        setBlogData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode === 401) {
          localStorage.clear();
          navigate('/');
        }
        toast.error('Something went wrong');
      })
      .finally(() => setLoading(false));
  };


  const handleToggleActive = (id, isActive) => {
    const newStatus = !isActive;

    const apiFunction = newStatus ? blogActive : blogDeactivate;

    apiFunction(id)
      .then((res) => {
        fetchBlogData();
      })
      .catch((error) => {
        console.error(
          `Failed to ${newStatus ? 'activate' : 'deactivate'} blog post:`,
          error
        );
        toast.error('Something went wrong');
      });
  };

  return (
    <div className="min-w-[90%] bg-white shadow-lg p-3 rounded-md max-w-[500px] min-h-[456px] flex flex-col justify-between">
      <ToastContainer />
      {loading && <Loader />}
      <div>
        <h1 className="m-0 p-0 text-2xl font-bold">Blog List</h1>
        <hr className="my-5" />
        <div className="overflow-x-auto">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sr. No
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Tags
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {currentProducts.length > 0 ? (
                currentProducts.map((product, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {product?.title}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap max-w-60">
                      <div className="text-sm text-gray-500 flex gap-1 flex-wrap">
                        {product?.tags?.map((item, i) => (
                          <div
                            className="px-2 py-1 rounded-full bg-blue-400 text-white"
                            key={i}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {moment(product?.createdAt).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {product?.isActive ? 'Active' : 'In-Active'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-4">
                        <ToolTip text={'Edit'}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            className="h-5 w-5 fill-green-400 hover:fill-green-500 cursor-pointer"
                            onClick={() => {
                              handleEdit(product);
                              dispatch(setOption('add'));
                            }}
                          >
                            <path d="m18.813,10c.309,0,.601-.143.79-.387s.255-.562.179-.861c-.311-1.217-.945-2.329-1.833-3.217l-3.485-3.485c-1.322-1.322-3.08-2.05-4.95-2.05h-4.515C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h3c.552,0,1-.448,1-1s-.448-1-1-1h-3c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h4.515c.163,0,.325.008.485.023v4.977c0,1.654,1.346,3,3,3h5.813Zm-6.813-3V2.659c.379.218.732.488,1.05.806l3.485,3.485c.314.314.583.668.803,1.05h-4.338c-.551,0-1-.449-1-1Zm11.122,4.879c-1.134-1.134-3.11-1.134-4.243,0l-6.707,6.707c-.755.755-1.172,1.76-1.172,2.829v1.586c0,.552.448,1,1,1h1.586c1.069,0,2.073-.417,2.828-1.172l6.707-6.707c.567-.567.879-1.32.879-2.122s-.312-1.555-.878-2.121Zm-1.415,2.828l-6.708,6.707c-.377.378-.879.586-1.414.586h-.586v-.586c0-.534.208-1.036.586-1.414l6.708-6.707c.377-.378,1.036-.378,1.414,0,.189.188.293.439.293.707s-.104.518-.293.707Z" />
                          </svg>
                        </ToolTip>

                        <ToolTip text="Delete">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Outline"
                            viewBox="0 0 24 24"
                            className="h-5 w-5 fill-red-400 hover:fill-red-500 cursor-pointer"
                            onClick={() => handleDelete(product?._id)}
                          >
                            <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
                            <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
                            <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
                          </svg>
                        </ToolTip>


                        {!product?.isDelete ? (
                          <div className="flex">
                            <label className="inline-flex relative items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={product?.isActive ? true : false}
                                readOnly
                                onChange={() =>
                                  handleToggleActive(product?._id, enabled)
                                }
                              />
                              <div
                                onClick={() => setEnabled(!enabled)}
                                className="w-[53px] h-[27px] bg-white rounded-full peer border border-orange peer-checked:after:translate-x-full peer-checked:after:border-black after:absolute flex items-center after:left-[4px]  after:bg-primary after:border-gray-300  after:rounded-full after:h-[21px] after:w-[22px] after:bg-blue-400 after:transition-all "
                              ></div>
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <img
                      src={notFound}
                      alt=""
                      className="w-auto h-[200px] block m-auto my-16"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this blog post?"
      />
    </div>
  );
};

export default ManageProduct;
