export const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 ">
            <div className="bg-white  rounded-lg shadow-lg p-6 w-full max-w-4xl mx-auto relative mt-24 mb-12 max-h-[800px] overflow-auto">
                <button
                    className="absolute top-2 right-2 text-5xl text-gray-600 hover:text-gray-900"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};