import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { selectOption } from '../redux/ManageSlice';
import ManageProduct from './ManageProduct';
import CreateProduct from './CreateProduct';
import ManageProductAdmin from './Admin/manageProductAdmin';
import UserList from './UserList';

const Dashboard = () => {
  const selectedOption = useSelector(selectOption);
  const [editData, setEditData] = useState(null);
  const getData = localStorage.getItem('user');
  const userType = JSON.parse(getData);

  return (
    <div className="bg-neutral-100 w-full">
      <div
        className="flex justify-center items-center w-full mt-24 overflow-auto"
      >
        {selectedOption === 'manage' && userType?.userType === 'user' && (
          <ManageProduct editData={editData} setEditData={setEditData} />
        )}

        {selectedOption === 'manage' && userType?.userType === 'admin' && (
          <ManageProductAdmin editData={editData} setEditData={setEditData} />
        )}

        {selectedOption === 'add' && userType?.userType === 'user' && (
          <CreateProduct editData={editData} setEditData={setEditData} />
        )}
        {selectedOption === "users" && userType?.userType === "admin" && (
          <UserList />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
