import React, { useState } from 'react';
import Layout from '../layout/layout';
import style from '../styles/Form.module.css';
import { HiAtSymbol, HiFingerPrint, HiOutlineUser } from 'react-icons/hi';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { registerValidate } from '../lib/Validate';
import { ToastContainer, toast } from 'react-toastify';


export default function Register() {
  const [show, setShow] = useState({ password: false, cpassword: false });
  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validate: registerValidate,
    onSubmit,
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  async function onSubmit(values) {
    try {
      const response = await fetch(`${baseUrl}/api/blog-user/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });


      if (!response.ok) {
        throw new Error('Failed to login');
      }

      const data = await response.json();
      localStorage.setItem('token', data?.data?.token);
      const userRecord = data?.data?.findUser
      localStorage.setItem('user', JSON.stringify({ ...userRecord, id: userRecord?._id }));
      navigation('/dashboard');

    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('invalid Credentials');
    }
  }

  return (
    <Layout>
      <ToastContainer />
      <title>Register</title>
      <section className="w-3/4 mx-auto flex flex-col gap-10">
        <div className="title">
          <h1 className="text-gray-800 text-4xl font-bold py-4 ">Register</h1>
          <p className="w-3/4 mx-auto text-gray-400 ">
            Please enter your Register datails!
          </p>
        </div>
        {/* form */}
        <form className="flex flex-col gap-5 " onSubmit={formik.handleSubmit}>
          <div
            className={`${style.input_group} ${formik.errors.firstName && formik.touched.firstName
              ? 'border-rose-500'
              : ''
              }`}
          >
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className={style.input_text}
              {...formik.getFieldProps('firstName')}
            />
            <span className="icon flex items-center px-4">
              <HiOutlineUser size={25} />
            </span>
          </div>
          {formik.errors.firstName && formik.touched.firstName ? (
            <span className="text-rose-500 flex">{formik.errors.firstName}</span>
          ) : (
            <></>
          )}
          <div
            className={`${style.input_group} ${formik.errors.lastName && formik.touched.lastName
              ? 'border-rose-500'
              : ''
              }`}
          >
            <input
              type={'text'}
              name="lastName"
              placeholder="Last Name"
              className={style.input_text}
              {...formik.getFieldProps('lastName')}
            />
            <span
              className="icon flex items-center px-4"
              onClick={() => setShow({ ...show, cpassword: !show.password })}
            >
              <HiFingerPrint size={25} />
            </span>
          </div>
          {formik.errors.lastName && formik.touched.lastName ? (
            <span className="text-rose-500 flex">{formik.errors.lastName}</span>
          ) : (
            <></>
          )}
          <div
            className={`${style.input_group} ${formik.errors.email && formik.touched.email
              ? 'border-rose-500'
              : ''
              }`}
          >
            <input
              type="email"
              name="email"
              placeholder="Email"
              className={style.input_text}
              {...formik.getFieldProps('email')}
            />
            <span className="icon flex items-center px-4">
              <HiAtSymbol size={25} />
            </span>
          </div>
          {formik.errors.email && formik.touched.email ? (
            <span className="text-rose-500 flex">{formik.errors.email}</span>
          ) : (
            <></>
          )}
          <div
            className={`${style.input_group} ${formik.errors.password && formik.touched.password
              ? 'border-rose-500'
              : ''
              }`}
          >
            <input
              type={`${show.password ? 'text' : 'password'}`}
              name="password"
              placeholder="Password"
              className={style.input_text}
              {...formik.getFieldProps('password')}
            />
            <span
              className="icon flex items-center px-4"
              onClick={() => setShow({ ...show, password: !show.password })}
            >
              <HiFingerPrint size={25} />
            </span>
          </div>
          {formik.errors.password && formik.touched.password ? (
            <span className="text-rose-500 flex">{formik.errors.password}</span>
          ) : (
            <></>
          )}
          <div className="input-button">
            <button type="submit" className={style.button}>
              Register
            </button>
          </div>

        </form>
        <p className="text-center text-gray-400 ">
          have an account?{' '}
          <a href={'/'} legacyBehavior>
            <a className="text-blue-700">Sign In</a>
          </a>
        </p>
      </section>
    </Layout>
  );
}
