import React, { useState } from 'react';
import style from '../styles/Form.module.css';
import { HiAtSymbol, HiFingerPrint } from 'react-icons/hi';
import { useFormik } from 'formik';
import login_validate from '../lib/Validate';
import Layout from '../layout/layout';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export default function Login() {
  const [show, setShow] = useState(false);
  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: login_validate,
    onSubmit,
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  async function onSubmit(values) {
    try {
      const response = await fetch(`${baseUrl}/api/blog-auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors'
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Failed to login');
      }

      const responseData = await response.json();
      localStorage.setItem('token', responseData?.data?.token);
      localStorage.setItem('user', JSON.stringify(responseData?.data?.userdata));
      navigation('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('invalid Credentials');
    }
  }

  return (
    <Layout>
      <ToastContainer />
      <title>Login</title>
      <section className="w-3/4 mx-auto flex flex-col gap-10">
        <div className="title">
          <h1 className="text-gray-800 text-4xl font-bold py-4 ">Explore</h1>
          <p className="w-3/4 mx-auto text-gray-400 ">
            Please enter your Login datails!
          </p>
        </div>
        {/* form */}
        <form className="flex flex-col gap-5" onSubmit={formik.handleSubmit}>
          <div
            className={`${style.input_group} ${formik.errors.email && formik.touched.email
              ? 'border-rose-600'
              : ''
              }`}
          >
            <input
              type="email"
              name="email"
              placeholder="Email"
              className={style.input_text}
              {...formik.getFieldProps('email')}
            />
            <span className="icon flex items-center px-4">
              <HiAtSymbol size={25} />
            </span>
          </div>
          {formik.errors.email && formik.touched.email ? (
            <span className="text-rose-500 flex">{formik.errors.email}</span>
          ) : (
            <></>
          )}
          <div
            className={`${style.input_group} ${formik.errors.password && formik.touched.password
              ? 'border-rose-600'
              : ''
              }`}
          >
            <input
              type={`${show ? 'text' : 'password'}`}
              name="password"
              placeholder="Password"
              className={style.input_text}
              {...formik.getFieldProps('password')}
            />
            <span
              className="icon flex items-center px-4"
              onClick={() => setShow(!show)}
            >
              <HiFingerPrint size={25} />
            </span>
          </div>
          {formik.errors.password && formik.touched.password ? (
            <span className="text-rose-500 flex">{formik.errors.password}</span>
          ) : (
            <></>
          )}
          <div className="input-button">
            <button type="submit" className={style.button}>
              Login
            </button>
          </div>
        </form>
        <p className="text-center text-gray-400 ">
          dont have an account yet?{' '}
          <a href={'/Register'} legacyBehavior>
            <a className="text-blue-700">Sign Up</a>
          </a>
        </p>
      </section>
    </Layout>
  );
}
