
import { createSlice } from '@reduxjs/toolkit';

export const selectedOptionSlice = createSlice({
  name: 'selectedOption',
  initialState: {
    select: 'manage',
  },
  reducers: {
    setOption: (state, action) => {
      state.select = action.payload;
    },
  },
});

export const { setOption } = selectedOptionSlice.actions;

export const selectOption = (state) => state.selectedOption.select;

export default selectedOptionSlice.reducer;



