export default function login_validate(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is Required!';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Password is Required';
  } else if (values.password.length < 8 || values.password.length > 20) {
    errors.password = 'Password must be between 8 and 20 characters long';
  } else if (!/[a-zA-Z0-9]/.test(values.password)) {
    errors.password = 'Password must contain at least one letter and one number';
  }

  return errors;
}

export function registerValidate(values) {
  const errors = {};

  
  if (!values.firstName) {
    errors.firstName = 'First Name is Required';
  } else if (!/^[a-zA-Z]+$/.test(values.firstName)) {
    errors.firstName = 'Invalid First Name';
  }

  if (!values.lastName) {
    errors.lastName = 'Last Name is Required';
  } else if (!/^[a-zA-Z]+$/.test(values.lastName)) {
    errors.lastName = 'Invalid Last Name';
  }

  if (!values.email) {
    errors.email = 'Email is Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Password is Required';
  } else if (values.password.length < 8 || values.password.length > 20) {
    errors.password = 'Password must be between 8 and 20 characters long';
  } else if (!/[a-zA-Z0-9]/.test(values.password)) {
    errors.password = 'Password must contain at least one letter and one number';
  }

  return errors;
}
