import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import notFound from "../assets/images/notFound.jpg";
import style from '../styles/Form.module.css';
import {
  blogUserDelete,
  blogUserList,
  updateUserToAdmin,
  userStatus,
} from "../service/api";
import Pagination from "./Pagination";
import { ConfirmationModal } from "../comman/ConfirmationModal ";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../comman/loader";
import ToolTip from "../comman/toolTip";


const UserList = () => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [userEnable, setUserEnable] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const loginUser = JSON.parse(localStorage.getItem("user"));
  const productsPerPage = 5;

  useEffect(() => {
    fetchBlogData();
  }, [currentPage, statusFilter]);

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    setLoading(true);
    blogUserDelete(deleteId)
      .then(() => {
        fetchBlogData();
        toast.success('Blog post deleted successfully');
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Failed to delete blog post:", error);
        toast.error('Something went wrong');
        setShowModal(false);
      })
      .finally(() => setLoading(false));
  };

  const fetchBlogData = () => {
    setLoading(true);
    blogUserList()
      .then((response) => {
        const filteredData = response?.data?.data.filter(
          (product) => !product.isDeleted
        );

        let filteredByStatus = filteredData;
        if (statusFilter === 'active') {
          filteredByStatus = filteredData.filter((product) => product?.isActive);
        } else if (statusFilter === 'inactive') {
          filteredByStatus = filteredData.filter(
            (product) => !product?.isActive
          );
        }

        setBlogData(filteredByStatus);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode === 401) {
          localStorage.clear();
          navigate('/');
          toast.error('Session expired, please log in again');
        } else {
          toast.error('Failed to fetch blog data');
        }
      })
      .finally(() => setLoading(false));
  };

  const handleToggleStatus = (id, isActive) => {
    const type = !isActive ? "active" : "in-active";
    userStatus(id, type)
      .then((res) => {
        fetchBlogData();
      })
      .catch((error) => {
        console.error(
          `Failed to ${!isActive ? "activate" : "deactivate"} blog post:`,
          error
        );
        toast.error(`Failed to ${!isActive ? 'activate' : 'deactivate'} blog post`);
      });
  };

  const filteredProducts = blogData.filter((product) => {
    const firstName = product?.firstName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const lastName = product?.lastName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return firstName || lastName;
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(filteredProducts?.length / productsPerPage);


  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSetAdmin = (id, user) => {
    const userType = user === "admin" ? "user" : "admin";
    const payload = {
      userId: id,
      userType: userType,
    };
    updateUserToAdmin(payload)
      .then(() => {
        fetchBlogData();
      })
      .catch(() => { });
  };
  return (
    <div
      className="min-w-[90%] bg-white shadow-lg p-3 rounded-md max-w-[500px] min-h-[456px] flex flex-col justify-between"
    >
      <div>
        {loading && <Loader />}
        <ToastContainer />
        <div className="flex justify-between items-center">
          <h1 className="p-0 text-2xl font-bold">Users List</h1>
          <div className="flex gap-3">
            <input
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={style.input_text}
            />
            <select
              className="block py-1 px-2 border cursor-pointer border-gray-300 bg-white rounded-md shadow-sm focus:outline-none"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>
        <hr className="my-5" />
        <div className="overflow-x-auto">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sr. No
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  userType
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {currentProducts?.length > 0 ? (
                currentProducts
                  .filter(product => !product?.isDeleted)
                  .map((product, index) => (
                    <tr key={product._id} className="hover:bg-gray-100">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {index + 1}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500 flex gap-2">
                          <span>{product?.firstName}</span>
                          <span>{product?.lastName}</span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="text-sm text-gray-500 flex gap-2">
                          <span className="ml-3">{product?.email}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap max-w-60">
                        <div className="text-sm text-gray-500 flex gap-1 flex-wrap">
                          {product?.userType}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          Created by <strong>{product?.author?.firstName}</strong> on{" "}
                          {moment(product?.createdAt).format("MMMM Do YYYY, h:mm a")}
                        </div>
                      </td>
                      <ToolTip text={!userEnable ? "Active" : "In-active"}>
                        {!(product?._id === loginUser?.id) && (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex">
                              <label className="inline-flex relative items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  className="sr-only peer"
                                  checked={product?.isActive || false}
                                  readOnly
                                  onChange={() =>
                                    handleToggleStatus(product?._id, userEnable)
                                  }
                                />
                                <div
                                  onClick={() => setUserEnable(!userEnable)}
                                  className="w-[53px] h-[27px] bg-white rounded-full peer border border-orange peer-checked:after:translate-x-full peer-checked:after:border-black after:absolute flex items-center after:left-[4px] after:bg-primary after:border-gray-300 after:rounded-full after:h-[21px] after:w-[22px] after:bg-blue-400 after:transition-all"
                                ></div>
                              </label>
                            </div>
                          </td>
                        )}
                      </ToolTip>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center space-x-4">
                          <ToolTip text={"Delete"}>
                            {!(product?._id === loginUser?.id) && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Outline"
                                viewBox="0 0 24 24"
                                className="h-5 w-5 fill-red-400 hover:fill-red-500 cursor-pointer"
                                onClick={() => handleDelete(product?._id)}
                              >
                                <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
                                <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
                                <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
                              </svg>
                            )}
                          </ToolTip>
                          {!(product?._id === loginUser?.id) && !product?.isDeleted && (
                            <div className="flex">
                              <label className="inline-flex relative items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  className="sr-only peer"
                                  checked={product?.userType === "admin"}
                                  readOnly
                                  onChange={() => {
                                    onSetAdmin(product?._id, product?.userType);
                                  }}
                                />
                                <div
                                  onClick={() => setEnabled(!enabled)}
                                  className="w-[53px] h-[27px] bg-white rounded-full peer border border-orange peer-checked:after:translate-x-full peer-checked:after:border-black after:absolute flex items-center after:left-[4px] after:bg-primary after:border-gray-300 after:rounded-full after:h-[21px] after:w-[22px] after:bg-blue-400 after:transition-all"
                                ></div>
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <img
                      src={notFound}
                      alt=""
                      className="w-auto h-[200px] block m-auto my-16"
                    />
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this blog post?"
      />
    </div>
  );
};

export default UserList;

