import React from 'react';

const Header = () => {
  const loginData = localStorage.getItem('user');
  const allData = JSON.parse(loginData);

  return (
    <div className="fixed bg-blue-400 h-20 px-16 items-center w-full flex justify-between z-50">
      <div className="text-white font-bold text-2xl">NEOVERCE</div>
      <div className="flex items-center gap-2">
        <div className="h-10 w-10 bg-white rounded-full flex justify-center items-center text-xl font-bold text-blue-400 shadow-2xl">
          {allData?.firstName.toUpperCase().charAt(0)}
        </div>
        <p className="m-0 p-0 text-white font-bold">
          {allData?.firstName} {allData?.lastName}
        </p>
      </div>
    </div>
  );
};

export default Header;
