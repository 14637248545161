import axios from 'axios';
const defaultHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  Authorization: '',
};

export function apiClient({
  url,
  data = {},
  method,
  headers = {},
  noHeaders,
  ...rest
}) {
  const token = localStorage.getItem('token') || '';
  if (token || null === '' || token || null) {
    delete axios.defaults.headers.common['Authorization'];
  }
  return new Promise((resolve, reject) => {
    defaultHeaders.Authorization = `Bearer ${token}`;
    axios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
      },
      data,
      ...rest,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
