import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOption, setOption } from '../redux/ManageSlice';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const dispatch = useDispatch();
  const selectedOption = useSelector(selectOption);
  const loginData = localStorage.getItem('user');
  const allData = JSON.parse(loginData);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  };

  return (
    <div
      className=" bg-white w-72 shadow-2xl mt-[80px] sticky top-[80px] p-5 justify-between flex flex-col"
      style={{ height: 'calc(100vh - 80px)' }}
    >
      <div className="cursor-pointer">
        <div className="text-center pt-10 pb-9 mb-9 border-b border-slate-100 ">
          <div className="h-24 w-24 rounded-full  m-auto text-4xl flex justify-center items-center font-bold text-white bg-blue-400">
            {allData?.firstName.charAt(0).toUpperCase()}
          </div>
          <p className="m-0 p-0 pt-5 text-blue-400 font-semibold">
            {allData?.firstName} {allData?.lastName}
            <br />
            {allData?.email}
          </p>
        </div>
        <li
          className={`list-none p-2 rounded-md font-semibold mb-2 ${selectedOption === 'manage'
            ? 'bg-blue-400 text-white'
            : 'hover:bg-blue-400 hover:text-white'
            }`}
          onClick={() => {
            dispatch(setOption('manage'))
            navigate('/dashboard')
          }}
        >
          Manage Post
        </li>
        {allData?.userType === 'admin' && (
          <li
            className={`list-none p-2 rounded-md font-semibold mb-2 ${selectedOption === 'users'
              ? 'bg-blue-400 text-white'
              : 'hover:bg-blue-400 hover:text-white'
              }`}
            onClick={() => {
              dispatch(setOption('users'))
            }
            }
          >
            Users List
          </li>
        )}
        {allData?.userType === 'user' && (
          <li
            className={`list-none p-2 rounded-md font-semibold mb-2 ${selectedOption === 'add'
              ? 'bg-blue-400 text-white'
              : 'hover:bg-blue-400 hover:text-white'
              }`}
            onClick={() => dispatch(setOption('add'))}
          >
            Add Post
          </li>
        )}
      </div>
      <li
        className="list-none p-2 rounded-md font-semibold bg-blue-400 text-white flex items-center gap-2 cursor-pointer"
        onClick={logout}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="fill-white"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path d="M11.5,16A1.5,1.5,0,0,0,10,17.5v.8A2.7,2.7,0,0,1,7.3,21H5.7A2.7,2.7,0,0,1,3,18.3V5.7A2.7,2.7,0,0,1,5.7,3H7.3A2.7,2.7,0,0,1,10,5.7v.8a1.5,1.5,0,0,0,3,0V5.7A5.706,5.706,0,0,0,7.3,0H5.7A5.706,5.706,0,0,0,0,5.7V18.3A5.706,5.706,0,0,0,5.7,24H7.3A5.706,5.706,0,0,0,13,18.3v-.8A1.5,1.5,0,0,0,11.5,16Z" />
          <path d="M22.561,9.525,17.975,4.939a1.5,1.5,0,0,0-2.121,2.122l3.411,3.411L7,10.5a1.5,1.5,0,0,0,0,3H7l12.318-.028-3.467,3.467a1.5,1.5,0,0,0,2.121,2.122l4.586-4.586A3.505,3.505,0,0,0,22.561,9.525Z" />
        </svg>
        Logout
      </li>
    </div>
  );
};

export default Sidebar;
