
const baseUrl = process.env.REACT_APP_BASE_URL;
export const PATH = {
    blog: {
        blogCreate: (id) => `${baseUrl}/api/blog/create/${id}`,
        blogGet: (id) => `${baseUrl}/api/blog/author/${id}`,
        blogDelete: (id) => `${baseUrl}/api/blog/delete/${id}`,
        blogUpdate: (id) => `${baseUrl}/api/blog/update/${id}`,
        blogActive: (id) => `${baseUrl}/api/blog/active/${id}`,
        blogDeactivate: (id) => `${baseUrl}/api/blog/in-active/${id}`,
        blogAdmin: () => `${baseUrl}/api/blog/admin`,
        blogUserList: () => `${baseUrl}/api/blog/admin/userlist`,
        userUpdateAdmin: () => `${baseUrl}/api/blog-user/updateUserType/update`,
        blogUserDelete: () => `${baseUrl}/api/blog-user/delete`,
        userActiveInActiveStatus: (id, type) => `${baseUrl}/api/blog-user/${type}/${id}`,
    }
};