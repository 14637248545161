import React, { useState, useRef } from 'react';

function MultiTagInput({setTags, tags}) {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (inputValue.trim() !== '') {
        setTags([...tags, inputValue.trim()]);
        setInputValue('');
      }
    }
  };
  const handleTagRemove = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };
  const handleInputKeyDown = (event) => {
    if (event?.key === 'Backspace' && inputValue === '' && tags?.length > 0) {
      setTags(tags.slice(0, tags.length - 1));
    }
  };
  return (
    <div className="tag-input-container">
      <div className="tags">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <button onClick={() => handleTagRemove(index)}>x</button>
          </div>
        ))}
         <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          onKeyDown={handleInputKeyDown}
          placeholder="Enter tags..."
          className="tag-input"
        />
      </div>
    </div>
  );
}
export default MultiTagInput;